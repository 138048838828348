@cmp-name: ~"cmp-tool-tip";

.@{cmp-name} {

  &__tooltip_wrapper {
    position: relative;
    display: inline-block;
  }

  &__tooltip_button {
    background-color: grey;
    border: none;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 10px;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tooltip_content {
    visibility: visible;
    width: 200px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    //bottom: -280%;
    left: 650%;
    margin-left: -100px;
    opacity: 0.9;
    transition: opacity 0.3s;
  }

  &__tooltip_content::after {
    content: "";
    border: 5px solid #0000;
    border-bottom-color: #555;
    margin-left: -5px;
    position: absolute;
    top: -25%;
    left: 5%;
  }
}