@import '../../styles/shared/variables.less';

@cmp-name: ~"cmp-pricepage";

.@{cmp-name} {
  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
  }

  &__column {
    border: 1px solid #ccc;
    border-radius: 0;
    padding: 16px;
    margin: 8px 0;
    flex: 1;
    max-width: calc(25% - 16px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__header, &__body, &__footer {
      margin-bottom: 8px;
    }

    &__header {
      min-height: 100px;

      &-title {
        font-weight: bold;
        font-size: 25px;
      }

      &-price {
        margin-top: 16px;

        .price-amount {
          font-size: 35px;
          font-weight: bold;
          display: block;
        }

        .price-period {
          font-size: 14px;
          display: block;
        }
      }
    }

    &__body {
      border-top: 1px solid #ccc;
      padding-top: 8px;
      margin-top: 16px;
      flex-grow: 1;
    
      &-list {
        list-style-type: none;
        text-align: left;

        &-img{
          width: 8px;
          height: 10px;
          margin-right: 8px;
        }
    
        li {
          position: relative;
        }
      }
    }

    &__footer {
      &-button {
        padding: 10px 20px;
        font-size: 14px;
        cursor: pointer;
        border: @display-none;
        background-color: @space-cadet;
        color: @white;
        border-radius: 4px;
        cursor: @cursor-pointer;
        transition: background-color 0.3s;
        
        &:hover {
          background-color: @midnight-blue;
          box-shadow: @box-shadow;        
        }
      }
    }
  }
}
