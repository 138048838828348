@import '../../../../styles/shared/variables.less';

@cmp-name: ~'cmp-reit-banner';

.@{cmp-name} {
  &_banner {
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #10304E;
    width: 100%;

    &__header {
      margin-top: 12px;
      margin-bottom: 8px;

      &_tbl {
        width: 100%;
        padding: 0 15px;

        @media only screen and (min-width: 100px) and (max-width: 599px) {
          tbody {
            tr {
              display: flex;
              flex-wrap: wrap;
            }
          }
        }


        &_col1 {
          display: @display-flex;
          flex-direction: @display-flex-column;

          &--name {
            font-family: @gotham-bold;
            font-size: @font-size-24;
            color: @white;
          }

          &--locations {
            font-size: @font-size-12;
            color: @white;
            margin: 10px 0px;

            &--more {
              font-family: @gotham-medium;

            }

            &--more:hover {
              text-decoration: underline;
            }
          }

        }

        &_col2 {
          width: 10%;
        }

        &__caaplScore {
          display: @display-none;
        }

        &_bse {
          margin-right: 15px;

          &_cont {

            &-title {
              display: @display-flex;
              align-items: @align-center;

              &--name {
                font-family: @gotham-bold;
                color: @white;
                font-size: @font-size-14;
              }

              &--live {
                margin-left: 5px;
                background-color: @midnight-blue;
                padding: 2px;
                font-family: @gotham-medium;
                font-size: @font-size-12;
                color: @white;
              }
            }

            &-subtitle {
              &-exchange {
                display: @display-flex;
                flex-direction: @display-flex-row;
                align-items: @align-center;

                &-value {
                  font-family: @gotham-bold;
                  color: @max-blue-green;
                  font-size: @font-size-26;
                  padding: 2px;
                }

                &-ticker {
                  font-family: @gotham-medium;
                  font-size: @font-size-12;
                  color: @verdigris;
                }
              }
            }
          }

        }

        &_primary {
          color: @light-cyan;

          &-container {
            text-align: @align-right;

            @media only screen and (min-width: 100px) and (max-width: 599px) {
              text-align: @align-left;
            }

            &-assets {
              &--link {
                color: @white;
                font-size: @font-size-12;

                &--hover {
                  margin-left: 4px;
                  font-family: @gotham-medium;
                  cursor: @cursor-pointer;
                }

                &--hover:hover {
                  text-decoration: @text-dec-line-underline;
                }
              }
            }

            &-date {
              color: @white;
              font-size: @font-size-12;
              margin-top: 2px;

              &--name {
                &--time {
                  font-family: @gotham-medium;
                }
              }
            }
          }
        }
      }
    }

    &__body {
      &_flex {
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }

      &_tbl {
        width: 100%;
        padding: 0 15px;

        @media only screen and (min-width: 100px) and (max-width: 599px) {
          tbody {
            tr {

              display: flex;
              flex-wrap: wrap;

              
              td {
                margin-top: 8px;
                margin-right: 12px;

                :only-child{
                  flex-grow: 12;
                }

                :last-child{
                  width:200px;
                }
              }
            }

          }
        }

        &-col1hr {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          flex-wrap: wrap;

          &--hr {
            background: white;
            height: 0.2px;
            width: 100%;
          }
        }

        &-title {
          font-family: @gotham-bold;
          font-size: @font-size-14;
          color: @cyan-cornflower-blue;
        }

        &-value {
          font-family: @gotham-bold;
          font-size: @font-size-24;
          color: @max-blue-green;
          line-height: 20px;
          white-space: pre-wrap;

          &-sponsors {
            font-family: @gotham-bold;
            font-size: @font-size-18;
            
            &-link {
              cursor: @cursor-pointer;
              font-size: @font-size-14;
            }

            &-link:hover {
              color: @white;
              text-decoration: underline;
            }
          }

        }
      }
    }

    &__foot {
      padding: 0 15px;
      margin-bottom: 10px;

      &-description {
        font-size: @font-size-12;
        color: @light-cyan ;
      }
    }
  }
}