@import '../../../../../styles/shared/variables.less';

.wacc-container {
  border: 1px solid #ccc;
  //padding: 16px;
  border-radius: 8px;
  padding: 10px;
}

.range-slider-container {
  margin-bottom: 8px;
  width: 100%;
}

.range-slider-container label {
  font-family: @gotham-bold;
  background-color: @bubbles;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: @border-radius-8;
  width: 94%;
}

.range-slider-container input[type="range"] {
  width: 100%;
  margin-bottom: 8px;
  padding: 0;
}

.range-slider-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.wacc-display,
.discount-rate-display {
  text-align: center;
  //font-size: 24px;
  font-weight: bold;
  color: black;
  margin-top: 16px;
}

.wacc-display-head {
  display: block;
  padding: 12px;
  background-color: #E9FCF9;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wacc-display--v {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-value {
    text-align: center;
    color: #1a73e8;
    margin-top: 16px;
    font-size: 24px;
    font-weight: bold;
  }
}