.tooltip {
    position: absolute;
    text-align: center;
    width: auto;
    height: auto;
    padding: 8px;
    font: 12px sans-serif;
    background: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    pointer-events: none;
    opacity: 0.9;
    visibility: hidden; 
}
.axis line {
    stroke: black;
    stroke-width: 0.5;
  }
