@import '../../styles/shared/variables.less';

@cmp-name: ~"cmp-signup";

.@{cmp-name} {
  width: 345px;
  height: 450px;
  padding: 0px 20px;
  background: @white;
  border-radius: @border-radius-6;

  &__header {
    width: 345px;
    font-size: @font-size-12;
  }

  &__form {
    margin-top: 10px;
  }

  &__field {
    width: 100%;
    //margin-bottom: 20px;

    &--shake {
      animation: shake 0.3s ease-in-out;
    }
    
    &--error .cmp-signup__input {
      border-color: @red;
    }

    label {
      font-weight: @font-weight-700;
      font-size: @font-size-12;
    }
  }

  @keyframes shake {
    0%, 100% {
      margin-left: 0px;
    }
    20%, 80% {
      margin-left: -12px;
    }
    40%, 60% {
      margin-left: 12px;
    }
  }

  &__input-area {
    height: 35px;
    width: 100%;
    position: @position-relative;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  &__input {
    width: 345px;
    height: 35px;
    outline: @display-none;
    padding: 0 40px;
    font-size: @font-size-18;
    background: @display-none;
    caret-color: @ultra-m-blue;
    border-radius: @border-radius-6;
    border: 1px @border-solid @plate-state;
    border-bottom-width: 2px;
    transition: all 0.2s ease;

    &:hover {
      border-color: @ultra-m-blue ;
    }

    &:focus {
      border-color: @ultra-m-blue;
    }

    &::placeholder {
      color: @plate-state;
      font-size: @font-size-17;
    }
    
  }

  &__icon {
    position: @position-absolute;
    font-size: @font-size-18;
    pointer-events: @display-none;
    transform: translateY(-50%);
    left: 15px;
    color: @plate-state;
    transition: color 0.2s ease;
    height: 35%;
    top: 20px;
  }

  &__captcha{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px
  }

  &__error-txt {
    color: @red;
    font-size: @font-size-12;
    position: @position-absolute;
    top: 40px;
    right: 0px;
  }

  &__submit-btn {
    display: @display-flex;
    justify-content: @align-center;

    .cmp-signup__submit {
      height: 34px;
      margin-top: 8px;
      color: @white;
      border: @display-none;
      border-radius: @border-radius-4;
      background: @ultra-m-blue;
      cursor: @cursor-pointer;
      border-bottom: 2px @border-solid @black;
      transition: all 0.3s ease;
      width: 98%;
      margin-bottom: 5px;

      &:hover {
        background: @royal-blue;
      }

      &:disabled{
        background: grey;
      }
    }
  }

  &__login-text {
    text-align: @align-center;
    font-size: @font-size-13;
    margin-top: 2px;
  }

  &__link {
    color: @ultra-m-blue;
    text-decoration: @display-none;

    &:hover {
      text-decoration: @text-dec-line-underline;
    }
  }
  &__pwdstrength {
    font-size: 10px;
    top: 214px;
    position: absolute;
    right: 588px;
  }
}