@import '../../../../../styles/shared/variables.less';

@cmp-name: ~"cmp-time-series-chart";

.@{cmp-name} {
    margin: 12px;
    padding-top: @ts-padding-top;
    padding-bottom: @ts-padding-bottom;

    &__legend {
        display: @display-flex;
        justify-content: @align-center;
        align-items: @align-center;
        font-family: @gotham-medium;
    }

    &__circle {
        padding-right: @ts-padding-right;
        width: @ts-circle-width;
        height: @ts-circle-height;
        border-radius: @ts-circle-border-radius;
        margin-right: @ts-circle-margin-right;
    }

    &__navbar {
        width: 100%;
        height: 47px;

        &-btn {
            border: @display-none;
            outline: @display-none;
            padding: 10px 16px;
            cursor: @cursor-pointer;
            //font-size: @font-size-18;
            background-color: @white;
            font-family: @gotham-bold;
            color: @dim-gray;

            &:hover {
                border-bottom: 3px @border-solid @blue;
                background-color: #e9f7ea;
            }

            &--active {
                border-bottom: 3px @border-solid @blue;
                color: @blue;
            }
        }
    }
}

.tooltip {
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    display: none;
    pointer-events: none;
}
