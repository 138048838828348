@import "../../styles/shared/variables.less";

@cmp-name: ~'cmp-card-panel';

.@{cmp-name} {
  display: @display-flex;
  //align-items: @align-center;
  flex-wrap: @display-flex-wrap;
  justify-content: @align-center;
  margin-top: 6px;
}