
.card-header {
    padding: 8px 16px;
    border-bottom: 1px solid #ccc ;
    table.matrixHeaderTable{
        width: 100%;
        h2{
            font-size: 24px;
            margin: 3px;
        }
        p{
            font-size: 12px;
            margin: 3px;
        }
        tbody tr td{
            // width: 17%;
            h6{
                font-size: 11px;
                margin: 3px;
                font-weight: normal;
            }
            p{
                font-size: 12px;
                margin: 3px;
                font-weight: normal;
            }
            
        }

    .nsebse{
        padding-left: 13px;
        span{
            color: #696969;
            font-size: 14px;
                .live{
                    background-color: #DBEC71;
                    color: #000;
                    padding: 2px;
                    font-size: 10px;
                }
        }
        p{
            color: #2EAE32;
            font-size: 26px;
            font-weight: bold;
            margin: 0px 10px 4px 0px;
            span{
                font-weight: normal;
                font-size: 12px;
            }
        }
      
    }
    .officeListing{
        p{
            font-size: 11px;
            font-weight: normal;
        }
    }
    }
}

.card-body {
    padding: 8px 16px;
    table.matrixHeaderTable{
        width: 100%;
  
        tbody tr td{
                width: 16%;
                h6{
                    font-size: 11px;
                    margin: 3px;
                    font-weight: normal;
                }
                p{
                    font-size: 14px;
                    margin: 3px;
                    font-weight: bold;
                }
                
            }
    }
}

.card-body img {
    width: 100%;
    height: auto;
}

.card-footer {
    padding: 8px 16px;
    text-align: left;
    p{
        color: #696969;
        margin: 0px;
        font-size: 12px;
    }
}


