@import '../../../../styles/shared/variables.less';
@cmp-name: ~'cmp-adjacent-table';

.@{cmp-name} {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 35px;
  //margin-top: 16px;

  &__table {
    width: 100%;
    overflow-x: auto;
    border-collapse: separate;
    border: 1px solid #ccc;
    overflow-y: auto;
    border-spacing: 0;
    border-radius: 0 0 8px 8px;



    tr:last-child {
      td:first-child {
        border-radius: 0 0 0 8px;
      }

      td:last-child {
        border-radius: 0 0 8px 0;
      }
    }

    &--th {
      font-family: @gotham-medium;
      background-color: #dadbe5;
      height: 27px;
      padding: 10px;
      font-size: 16px;
      border: none;
      text-align: left;
      position: sticky;
      z-index: 9;
      top:0
    }

    &--td {
      background-color: #fff;
      border-bottom: 1px solid #ccc;
      padding: 15px;
      font-size: 14px;
      white-space: pre-wrap;

      &:first-child {
        width: 25%;
      }

      &:first-child::after {
        width: 15%;
      }
    }
  }
}

.graph_main {
  width: 100%;
  display: flex;

  div.graph_image {
    width: 70%;
    height: 350px;

    img {
      width: 97%;
      height: 95%;
    }
  }

  div.graph_content {
    width: 30%;
    display: flex;
    flex-direction: column;

    span {
      margin: 10px;
      font-weight: 600;
    }

    button {
      width: 100%;
      border-radius: 6px;
      background-color: #e9fcf9;
      margin: 5px 5px;
      border: none;
      padding: 10px 16px 10px 16px;
      text-align: left;
    }
  }
}

.section_header {
  font-weight: 600;
  background-color: #e9f7ea;
  width: 100%;
  // height: 47px;
  padding: 15px 10px;
}