@import '../../styles/shared/variables.less';
@cmp-name: ~'cmp-hero-banner';

.@{cmp-name} {

  //width: 1440px;
  //margin: 0 auto;
  &__banner {
    color: @white;

    &-title {
      //font-size: @font-size-34 ;
      //font-size: max(calc(@font-size-12 + 1vw),34px) ;
      font-size: clamp(12px, calc(@font-size-12 + 1vw), 34px);

      font-family: @gotham-bold;
    }

    &-subtitle {
      text-align: @align-center;
      //font-size: @font-size-16;
      font-family: @gotham-book;
      font-size: max(calc(4px + 1vw),16px) ;
    }
  }


  &__container {

    &--img {
      width: calc(98.9% + 1vw);
    }

    /* Extra small devices (phones, 600px and down) */
    @media only screen and  (min-width: 100) and (max-width: 599px) {
      &--img {
        height: 150px;
      }
    }

    // /* Small devices (portrait tablets and large phones, 600px and up) */
    // @media only screen and (min-width: 600px)and (max-width: 767px) {
    //   &--img {
    //     width: 600px;
    //   }
    // }

    // /* Medium devices (landscape tablets, 768px and up) */
    // @media only screen and (min-width: 768px)and (max-width: 991px) {
    //   &--img {
    //     width: 800px;
    //   }
    // }

    // /* Large devices (laptops/desktops, 992px and up) */
    // @media only screen and (min-width: 992px) and (max-width: 1199) {
    //   &--img {
    //     width: 900px;
    //   }
    // }

    // /* Extra large devices (large laptops and desktops, 1200px and up) */
    // @media only screen and (min-width: 1200px) {
    //   &--img {
    //     width: 1240px;
    //   }
    // }
  }
}