@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-dashboard';

.@{cmp-name} {
  &__container {}

  &__footnote {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px;
    background-color: @space-cadet;

    @media only screen and (min-width: 100px) and (max-width: 991px) {
      flex-direction: column;
      justify-content: normal;
      //height: 350px;
    }

    @media only screen and (min-width: 992px) and (max-width: 1199) {
      justify-content: center;
    }


    &-vr {
      background-color: @bubbles;
      width: 0.5px;
      height: 200px;
      position: relative;
      top: 54px;

      @media only screen and (min-width: 100px) and (max-width: 991px) {
        height: 0.5px;
        width: 87%;
        position: relative;
        top: 10px;
        left: 24px;
      }
    }

    &-tab {
      padding: 10px 20px;
      color: #fff;
      width: 33%;
      text-align: justify;
      //height: 150px;
      background-color: @space-cadet;
      padding: 18px 25px;
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 100px) and (max-width: 991px) {
        width: 90%;
        //height: 90px;
        //padding: 18px 25px 0px 25px;
        padding: 10px 18px;
      }

      &--title {
        font-family: @gotham-bold;
        margin: 10px 0px 15px;
        font-size: 20px;
        color: @max-blue-green;

        @media only screen and (min-width: 100px) and (max-width: 991px) {
          font-size: 16px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199) {
          font-size: 18px;
        }
      }

      &--subtitle {
        color: #FDFDFE;
        font-size: 16px;
        font-weight: normal;

        @media only screen and (min-width: 100px) and (max-width: 991px) {
          font-size: 12px;
        }

        @media only screen and (min-width: 992px) and (max-width: 1199) {
          font-size: 14px;
        }
      }

    }
  }
}