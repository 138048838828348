@import '../../styles/shared/variables.less';

@cmp-name: ~'cmp-search';

.@{cmp-name} {
  display: @display-flex;
  justify-content: @align-center;
  align-items: @align-center;

  &__container {
    &_searchbox {
      display: @display-flex;

      &-mainfilter {
        width: 170px;


        @media only screen and (min-width: 100px) and (max-width: 599px) {
          width: 125px;
        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px)and (max-width: 767px) {
          width: 140px;
        }

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px)and (max-width: 991px) {
          width: 160px;
        }

        .css-b62m3t-container {
          font-size: @font-size-12;


          .css-1nmdiq5-menu {
            padding: 0px;
            margin: 2px;
          }

          //Dropdown no-chnage
          .css-13cymwt-control {
            min-height: 30px;
            height: 34px;
            border-radius: 4px 0px 0px 4px;
            border-color: hsl(0, 0%, 90%);

            .css-1xc3v61-indicatorContainer {
              svg {
                height: 15px !important;
              }
            }

            .css-1hb7zxy-IndicatorsContainer {
              .css-1u9des2-indicatorSeparator {
                display: none;

                .css-1xc3v61-indicatorContainer {
                  svg {
                    height: 15px !important;
                  }
                }
              }
            }
          }

          //Dropdown on-select event
          .css-t3ipsp-control {
            min-height: 30px;
            height: 34px;
            border-radius: 4px 0px 0px 4px;

            .css-1hb7zxy-IndicatorsContainer {
              .css-1u9des2-indicatorSeparator {
                display: none;

                .css-1xc3v61-indicatorContainer {
                  svg {
                    height: 15px !important;
                  }
                }
              }
            }
          }
        }
      }

      &-subfilter {
        width: 385px;
        padding-left: 1px;

        @media only screen and (min-width: 100px) and (max-width: 599px) {
          width: 230px;
        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px)and (max-width: 767px) {
          width: 260px;
        }

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px)and (max-width: 991px) {
          width: 290px;
        }


        .css-b62m3t-container {
          font-size: @font-size-12;
          z-index: 30;

          .css-1nmdiq5-menu {
            padding: 0px;
            margin: 2px;
          }
        }

        //Dropdown no-chnage
        .css-13cymwt-control {
          min-height: 30px;
          height: 34px;
          border-radius: 0px 4px 4px 0px;
          border-color: hsl(0, 0%, 90%);

          .css-1xc3v61-indicatorContainer {
            svg {
              height: 15px !important;
            }
          }

          .css-1hb7zxy-IndicatorsContainer {
            .css-1u9des2-indicatorSeparator {
              display: none;

              .css-1xc3v61-indicatorContainer {
                svg {
                  height: 15px !important;
                }
              }
            }
          }
        }

        //Dropdown-container when diasable
        .css-3iigni-container {
          font-size: @font-size-12;
          pointer-events: @cursor-not-allowed;

          //Dropdown when diasable
          .css-16xfy0z-control {
            min-height: 30px;
            height: 34px;
            border-radius: 0px 4px 4px 0px;

            .css-1hb7zxy-IndicatorsContainer {
              .css-894a34-indicatorSeparator {
                display: none;
              }

              .css-1xc3v61-indicatorContainer {
                svg {
                  height: 15px !important;
                }
              }
            }
          }
        }

        //Dropdown on-select event
        .css-t3ipsp-control {
          min-height: 30px;
          height: 34px;
          border-radius: 0px 4px 4px 0px;

          .css-1hb7zxy-IndicatorsContainer {
            .css-1u9des2-indicatorSeparator {
              display: none;

              .css-1xc3v61-indicatorContainer {
                svg {
                  height: 15px !important;
                }
              }
            }
          }
        }
      }
    }
  }

}