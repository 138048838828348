@import '../../styles/shared/variables.less';
@cmp-name: ~"cmp-footer";

.@{cmp-name} {
  font-family: @gotham-medium;
  display: @display-flex;
  text-align: @align-left;
  font-size: 12px;
  color: @white;
  bottom: 0;
  left: 0;
  background-color: @chinese-black;
  justify-content: @content-space-between;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  margin-top: 0.3px;

  &__discailmer {
    cursor: pointer;
    text-decoration: @text-dec-line-underline;
  }

}

.close-btn {
  font-family: @gotham-medium;
  font-size: @font-size-12;
  padding: 10px;
  background-color: @space-cadet;
  color: @white;
  height: 35px;
  border: @display-none;
  cursor: @cursor-pointer;
  border-radius: @filter-border-radius-25;
  width: 100px;
}

.close-btn:hover {
  background-color: @midnight-blue;
}