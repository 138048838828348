@import '/src/styles/shared/variables.less';


@cmp-name: ~"cmp-financial-compare-table";

.@{cmp-name} {
  .css-1p3m7a8-multiValue{
    background-color: @light-cyan;
  }

  .css-1dimb5e-singleValue{
    background-color: @light-cyan;
    padding: 2px;
    border-radius: @border-radius-6;
    font-size: @font-size-14;
    padding: 4px 6px;
  }


  .titleFilter {
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      margin: 0px;
      font-size: 20px;
      font-weight: 400;
      width: 40%;
    }

    .filterMain {
      z-index: 10;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        border-radius: 5px;
        border: 1px solid #ccc;
        padding: 5px 10px;
        vertical-align: middle;
        margin: 0px 5px;
      }

      div {

        // height: 20px;
        .basic-multi-select {
          font-family: @gotham-book;
         // width: 16vw;

          .select__control {
            min-height: 30px;

            .select__value-container {
              padding: 0px 2px;

              .select__multi-value {
                border-radius: 5px;

                .select__multi-value__label {
                  font-size: 70%;
                }
              }

              .select__input-container {
               // display: none;
              }
            }

            .select__indicators {
              .select__indicator {
                padding: 4px;
              }

              span.select__indicator-separator {
                display: none;
              }
            }
          }

        }
      }

    }
  }

  .tableHeader {
    margin-top: 16px;

    span {
      color: #000;
      // text-align: right;
      background-color: #e9f7ea;
      width: 98.3%;
      font-size: 16px;
      font-weight: bold;
      display: block;
      padding: 8px;
    }

    .tableGrid {
      width: 100%;
      //height: 200px;
      overflow-x: auto;
      border-collapse: collapse;
      border: 1px solid #ccc;
      overflow-y: auto;

      th {
        background-color: #DADBE5;
        height: 27px;
        padding: 10px;
        font-size: 12px;
        border: none;
        font-weight: normal;
        text-align: left;
      }

      .location-header td {
        font-weight: normal;
        color: #121212;
        background-color: #e9fcf9;
        text-align: left;
      }

      td {
        background-color: #fff;
        border-bottom: 1px solid #ccc;
        padding: 12px;
        font-size: 14px;

        &:first-child {
          width: 30%;
        }
      }

      .subMaintitle {
        font-family: @gotham-medium;
        background-color: @max-blue-green;
        font-size: 14px;
      }

      .subtitle {

        background-color: @bubbles;
        font-size: 14px;
      }

      .reitsName {
        th {
          font-family: @gotham-bold;
          text-align: center;
          font-size: 14px;
          height: 80px;

          &:nth-child(1) {
            background-color: transparent;
            width: 30%;
          }

          &:nth-child(2) {
            background-color: extract(@reits-logo-Bg, 1);
          }

          &:nth-child(3) {
            background-color: extract(@reits-logo-Bg, 2);
          }

          &:nth-child(4) {
            background-color: extract(@reits-logo-Bg, 3);
          }

          &:last-child {
            width: 10%;
            background-color: #fff;
          }
        }

        .addReits {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .close {
          width: 10%;
          padding: 1px;
          position: relative;
          left: 89%;
          top: -25px;
          background-clip: text;
        }
      }

      td.reitsLastCol {
        border-left: 1px solid #dadbe5;
        border-right: 1px solid #dadbe5;
      }
    }
  }
}