.dynamic-dropdown-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    text-align: left;
}

.dynamic-dropdown-table th, .dynamic-dropdown-table td {
    //padding: 12px 15px;
    //border: 1px solid #ddd;
}

.dynamic-dropdown-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.dynamic-dropdown-table tbody tr:nth-of-type(even) {
    background-color: #f9f9f9;
}

.dynamic-dropdown-table tbody tr:hover {
    background-color: #f1f1f1;
}

.add-row-btn, .remove-row-btn {
    width: 35px !important;
    height: 35px !important;;
    border-radius: 50% !important;;
    border: 1px solid #333 !important;;
    background-color: #fff !important;;
    cursor: pointer;
    text-align: center;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-row-btn:hover, .remove-row-btn:hover {
    background-color: #e7e7e7;
}
